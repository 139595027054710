function OptionMenu({ page, selectedPage, handleClick }) {
  const Icon = page?.icon;

  return (
    <div
      className={`flex cursor-pointer flex-row items-center gap-x-1 whitespace-nowrap hover:text-secondary-900 ${selectedPage ? 'border-0 border-b-2 border-solid border-secondary font-semibold text-secondary' : 'border-0 border-b-2 border-solid border-white'} pb-3`}
      onClick={() => handleClick()}
    >
      {page.icon && (
        <Icon
          size={16}
          className={`${selectedPage ? 'text-secondary' : 'text-neutral-600'} hover:text-secondary-900`}
        />
      )}
      <label className="m-0 hidden cursor-pointer md:block">
        {page?.title}
      </label>
      <label className="m-0 block md:hidden">
        {page?.mobileTitle ?? page?.title}
      </label>
    </div>
  );
}

export default function BasicMenu({
  pages,
  horizontal,
  selectedPage,
  handleClick,
}) {
  return (
    <div
      className={`flex rounded-tl-lg rounded-tr-lg border border-solid border-neutral-300 bg-white px-5 pt-5 text-neutral-600 ${horizontal ? 'flex-row overflow-x-scroll md:overflow-x-hidden' : 'flex-col'} gap-8 text-sm `}
    >
      {Object.keys(pages).map((key) => (
        <OptionMenu
          page={pages[key]}
          selectedPage={selectedPage === key}
          handleClick={() => handleClick(key)}
          key={key}
        />
      ))}
    </div>
  );
}
