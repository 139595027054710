import { useState, useEffect, useCallback } from 'react';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import { FiEdit } from 'react-icons/fi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { BiSolidEdit } from 'react-icons/bi';
import { PagePath } from '../pages-config';
import api from '@services/api';
import PageHeader from '@components/page-header';
import LoadingAnimation from '@components/LoadingAnimation';
import { useAlert } from '@/hooks/useAlert';

const LOADING_TIMEOUT = 500;
const MASTER_TITLE = 'Fabricantes';

export default function AdminFabricantes() {
  const { alert, showAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [total, setTotal] = useState(0);
  const [aircraftName, setAircraftName] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isNew, setIsNew] = useState(true);

  const fetchManufacturers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/manufacture');
      setManufacturers(response.data.items);
      setTotal(response.data.total);
    } catch (error) {
      showAlert('alert-danger', 'Erro!', 'Erro ao carregar dados.');
    } finally {
      setLoading(false);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchManufacturers();
  }, []);

  const handleClearForm = useCallback(() => {
    setIsNew(true);
    setSelectedId('');
    setAircraftName('');
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
        if (isNew) {
          await api.post('/manufacture', { aircraftName: aircraftName.trim() });
          showAlert('alert-success', 'Sucesso!', 'Cadastrado com sucesso.');
        } else {
          await api.patch(`/manufacture/${selectedId}`, {
            aircraftName: aircraftName.trim(),
          });
          showAlert('alert-success', 'Sucesso!', 'Alterado com sucesso.');
        }
        await fetchManufacturers();
        handleClearForm();
      } catch (error) {
        showAlert('alert-danger', 'Erro!', 'Erro ao salvar dados.');
      } finally {
        setLoading(false);
      }
    },
    [
      isNew,
      selectedId,
      aircraftName,
      showAlert,
      fetchManufacturers,
      handleClearForm,
    ],
  );

  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      if (!searchTerm.trim()) {
        await fetchManufacturers();
        return;
      }

      const response = await api.get('/manufacture/search', {
        params: { term: searchTerm },
      });
      setManufacturers(response.data.items);
      setTotal(response.data.total);
    } catch (error) {
      showAlert('alert-danger', 'Erro!', 'Erro na busca.');
    } finally {
      setLoading(false);
    }
  }, [searchTerm, fetchManufacturers, showAlert]);

  const handleSelect = useCallback((id, name) => {
    setLoading(true);
    setSelectedId(id);
    setAircraftName(name);
    setIsNew(false);
    setTimeout(() => setLoading(false), LOADING_TIMEOUT);
  }, []);

  const handleSearchTermChange = useCallback(
    async (term) => {
      setSearchTerm(term);
      if (!term.trim()) {
        await fetchManufacturers();
      }
    },
    [fetchManufacturers],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === 'Tab') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  return (
    <>
      {loading && <LoadingAnimation />}

      {alert.type && (
        <div className={`alert ${alert.type}`} role="alert">
          {alert.title && <h4>{alert.title}</h4>}
          {alert.message}
        </div>
      )}

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Aéreo - Fabricantes', link: PagePath.AdminFabricantes },
        ]}
        title={MASTER_TITLE}
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-9">
                  <label>Característica</label>
                  <input
                    type="text"
                    value={aircraftName}
                    onChange={(e) => setAircraftName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-3">
                  <button type="submit" disabled={loading}>
                    <span className={loading ? 'hide' : ''}>
                      {isNew ? (
                        <>
                          <PiCaretRightBold /> Salvar
                        </>
                      ) : (
                        <>
                          <FiEdit /> Alterar
                        </>
                      )}
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{MASTER_TITLE}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por"
                value={searchTerm}
                onChange={(e) => handleSearchTermChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={handleSearch} />
            </div>
            <div className="rightBtn">
              <div>{manufacturers?.length}</div>
              <div>de</div>
              <div className="listTotal">{total}</div>
              <button
                type="button"
                className="btn btnNew"
                onClick={handleClearForm}
              >
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm">
          <table className="tblDefault">
            <thead>
              <tr>
                <th scope="col" className="tdMaster">
                  Nome
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {manufacturers?.map((item) => (
                <tr
                  key={item.id}
                  onClick={() => handleSelect(item.id, item.aircraftName)}
                  className={selectedId === item.id ? 'trSelect' : ''}
                >
                  <td>{item.aircraftName}</td>
                  <td>
                    <BiSolidEdit />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
