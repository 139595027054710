import axios from 'axios';
import { refreshToken } from '@stores/authentication';

const api = axios.create({
  baseURL: import.meta.env.VITE_ENDPOINT,
});

const REFRESH_TOKEN_URL = '/authentication/refresh-token';
const SIGN_IN_URL = '/authentication/sign-in';

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // pra poder receber o userID do front foi feito essa gambi @joel @francisco
    // remover em breve 06/12/24
    const userId = localStorage.getItem('user_id');
    if (userId) {
      config.headers.userId = userId;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

let isRefreshing = false;
let failedQueue = [];

function processQueue(error, token = null) {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status || error?.statusCode || '';

    if (error?.code === 'ERR_NETWORK') {
      return Promise.reject('Não foi possível conectar ao servidor');
    }

    if (
      status === 401 &&
      ![REFRESH_TOKEN_URL, SIGN_IN_URL].includes(originalRequest.url)
    ) {
      if (originalRequest._retry) {
        return Promise.reject(error);
      }
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      try {
        const newAccessToken = await refreshToken();

        processQueue(null, newAccessToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);

        window.location.href = '/';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    if (error?.response?.data) {
      throw error.response.data;
    } else {
      throw error;
    }
  },
);

export default api;
