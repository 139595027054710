import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiUser3Line } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../stores/authentication';
import { styles } from './styles';

export function UserMenuInfos() {
  const { session } = useAuthentication();

  function profileFullName(data) {
    if (!data) return '';
    return `${data.firstName} ${data.familyName}`;
  }

  return (
    <div className={styles.userMenuInfoContainer()}>
      <div className={styles.userInfoName()}>
        <span>{profileFullName(session)}</span>
      </div>
      <div className={styles.userInfoEmail()}>
        <span>{session?.email || ''}</span>
      </div>
      <div className={styles.userInfoAgency()}>
        <span>Agência: {session?.agency?.nomeFantasia || ''}</span>
      </div>
    </div>
  );
}

export function UserMenuOptions({ handleMenuClick }) {
  const { methods } = useAuthentication();
  const navigate = useNavigate();

  function logout() {
    methods.signOut();
    navigate('/', {
      state: {
        from: window.location.href,
      },
    });
  }

  return (
    <ul className={styles.menuUserDataContent()}>
      <Link
        className={styles.menuUserDataLink()}
        to="/meus-dados"
        onClick={handleMenuClick}
      >
        <li>Meu perfil</li>
      </Link>
      <li
        className={styles.menuUserDataLogout()}
        role="button"
        onClick={logout}
      >
        Sair
      </li>
    </ul>
  );
}

export function UserMenu({ handleMenuClick }) {
  const [isOpened, setIsOpened] = useState(false);
  const { session } = useAuthentication();
  const menuRef = useRef(null);

  function handleIsOpenClick() {
    setIsOpened((prev) => !prev);
  }

  function profileFullName(data) {
    if (!data) return '';
    return `${data.firstName}`;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpened(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className={styles.userMenuContainer()} ref={menuRef}>
      <div
        className={styles.menuDesktopBadge()}
        role="button"
        onClick={handleIsOpenClick}
      >
        <RiUser3Line />
        {profileFullName(session)}
        {isOpened ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      {isOpened && (
        <div className={styles.userMenuDropdown()}>
          <UserMenuInfos />
          <UserMenuOptions
            handleMenuClick={() => {
              handleMenuClick();
              setIsOpened(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
