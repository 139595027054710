import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { InputText } from '@components/input';
import { InputDatePicker } from '@components/input-custom';
import { ModalFinancialBookingData } from '@components/modal/modal-external-bookings';
import { Select } from '@components/select';
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { getDateMinus12Months } from '@system/utils';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

const OrderBy = {
  lastPayments: 'Últimos pagamentos',
  firstPayments: 'Primeiros pagamentos',
  // lastCheckinDates: 'Últimas datas de check-in',
  // firstCheckinDates: 'Primeiras datas de check-in',
};
const OrderByApiMap = {
  [OrderBy.lastPayments]: 'LastPaymentEstimatedDate',
  [OrderBy.firstPayments]: 'FirstPaymentEstimatedDate',
  [OrderBy.lastCheckinDates]: 'LastCheckinDate',
  [OrderBy.firstCheckinDates]: 'FirstCheckinDate',
};

/**
 * @param {URLSearchParams} searchParams
 */
function getSearch(searchParams) {
  const search = {};
  searchParams.forEach((value, key) => (search[key] = value));
  return search;
}

export default function FinanceiroRecebiveisAdmin() {
  const { session } = useAuthentication();
  const navigate = useNavigate();

  if (session.role !== Role.Administrador) {
    navigate(PagePath.FinanceiroRecebiveis);
  }

  const startPaymentDateInputRef = useRef(null);
  const endPaymentDateInputRef = useRef(null);

  const [searchParams] = useSearchParams();
  const [submit, setSubmit] = useState(false);

  const [availableBookings, setAvailableBookings] = useState([]);
  const [orderBy, setOrderBy] = useState(OrderBy.lastPayments);
  const [page, setPage] = useState(0);
  const [foundResults, setFoundResults] = useState(true);
  const [hasFilter, setHasFilter] = useState(false);

  const filter = () => {
    const { startPaymentDate, endPaymentDate, bookingCode, clientName } =
      getSearch(searchParams);
    return {
      startPaymentDate: startPaymentDate ?? null,
      endPaymentDate: endPaymentDate ?? null,
      bookingCode: bookingCode ?? '',
      clientName: clientName ?? '',
    };
  };
  const [formFields, setFormFields] = useState(() => {
    const { startPaymentDate, endPaymentDate, bookingCode, clientName } =
      getSearch(searchParams);
    return {
      startPaymentDate: startPaymentDate ?? null,
      endPaymentDate: endPaymentDate ?? null,
      bookingCode: bookingCode ?? '',
      clientName: clientName ?? '',
    };
  });
  const [formErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalBookingId, setModalBookingId] = useState('');

  useEffect(() => {
    if (submit) {
      setSubmit(false);
      setAvailableBookings({});
      navigateFilter();
    }
  }, [submit, formFields]);

  function navigateFilter() {
    const query = makeQuery();
    navigate(`${PagePath.FinanceiroRecebiveisAdmin}?${query.toString()}`);
  }

  useEffect(() => {
    const loadAvailableBookings = async () => {
      const data = await fetchAvailableBookings(getSearch(searchParams));
      setAvailableBookings(data);
      setFoundResults(data.length > 0);
    };
    loadAvailableBookings();

    setHasFilter(
      Object.values(formFields).some(
        (value) => value !== null && value !== undefined && value !== '',
      ),
    );
  }, [searchParams]);

  async function handleChangeOrderBy(newOrder) {
    if (newOrder === orderBy) return;

    setOrderBy(newOrder);
    setPage(0);

    const data = await fetchAvailableBookings({
      ...formFields,
      page: 0,
      orderBy: newOrder,
    });
    setAvailableBookings(data);
    setFoundResults(data.length > 0);
  }

  async function handleLoadMore(newPage) {
    setPage(newPage);

    const data = await fetchAvailableBookings({
      ...formFields,
      page: newPage,
      orderBy,
    });
    setAvailableBookings([...availableBookings, ...data]);
    setFoundResults(data.length > 0);
  }

  function handleFieldChange(field, value) {
    setFormFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleStartPaymentDateChange(date) {
    const newEndPaymentDate =
      date > formFields.endPaymentDate ? null : formFields.endPaymentDate;

    flushSync(() =>
      setFormFields((prev) => ({
        ...prev,
        startPaymentDate: date,
        endPaymentDate: newEndPaymentDate,
      })),
    );

    endPaymentDateInputRef.current?.focus();
    endPaymentDateInputRef.current?.click();
  }

  function formatPrice(price) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(price);
  }

  function handleEndPaymentDateClick(showCalendar) {
    if (formFields.startPaymentDate) return showCalendar();
    startPaymentDateInputRef.current?.focus();
    startPaymentDateInputRef.current?.click();
  }

  async function fetchAvailableBookings({
    startPaymentDate,
    endPaymentDate,
    bookingCode,
    clientName,
    page,
    orderBy,
  } = {}) {
    const response = await api.get('hotel-external-bookings', {
      params: {
        clientName,
        page: page ?? 0,
        orderBy: OrderByApiMap[orderBy ?? OrderBy.lastPayments],
        status: 'created',
        startDatePayment: startPaymentDate
          ? new Date(startPaymentDate)
          : undefined,
        endDatePayment: endPaymentDate ? new Date(endPaymentDate) : undefined,
        code: bookingCode,
        paymentRequest: 1,
      },
    });
    return response.data.total > 0 ? response.data : [];
  }

  function handleOpenModal(id) {
    setModalBookingId(id);
    setOpenModal(true);
  }

  function makeDateInputValue(value) {
    return value ? format(value, 'dd/MM/yyyy') : '';
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!validateFormFields()) return;

    setSubmit(true);
  }

  function validateFormFields() {
    return Object.values(formFields).length > 0;
  }

  function makeQuery() {
    const { startPaymentDate, endPaymentDate, bookingCode, clientName } =
      formFields;

    return new URLSearchParams({
      ...(startPaymentDate && {
        startPaymentDate: format(startPaymentDate, 'yyyy-MM-dd'),
      }),
      ...(endPaymentDate && {
        endPaymentDate: format(endPaymentDate, 'yyyy-MM-dd'),
      }),
      ...(bookingCode && { bookingCode }),
      ...(clientName && { clientName }),
    });
  }

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Financeiro', link: PagePath.Financeiro },
          {
            label: 'Gestão de recebíveis de hotéis',
            link: PagePath.FinanceiroRecebiveis,
          },
        ]}
        title="Gestão de recebíveis"
      />

      <Container className="mt-4">
        <div className="mt-0">
          <form onSubmit={handleSubmit}>
            <div className="flex space-x-2.5">
              <div className="flex-grow">
                <label
                  className={`m-0 h-5 text-sm font-medium text-neutral-800`}
                >
                  Período de pagamento
                </label>
                <div className="flex flex-col space-y-2.5 lg:mt-2 lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
                  <InputDatePicker
                    name="startPaymentDate"
                    autoComplete="off"
                    placeholder="De"
                    icon={BiCalendar}
                    value={makeDateInputValue(formFields.startPaymentDate)}
                    startCalendar={getDateMinus12Months()}
                    startDay={getDateMinus12Months()}
                    monthStart={12}
                    noValidate
                    error={formErrors.startPaymentDate}
                    intervalStart={formFields.startPaymentDate}
                    changeOnly="start"
                    multiCalendar={false}
                    onDateChange={(date) => {
                      //handleFieldChange('startPaymentDate', date);
                      handleStartPaymentDateChange(date);
                    }}
                    ref={startPaymentDateInputRef}
                    disableResume={true}
                  />
                  <InputDatePicker
                    name="endPaymentDate"
                    autoComplete="off"
                    placeholder="Até"
                    icon={BiCalendar}
                    value={makeDateInputValue(formFields.endPaymentDate)}
                    startDay={getDateMinus12Months()}
                    noValidate
                    error={formErrors.endPaymentDate}
                    intervalStart={formFields.startPaymentDate}
                    intervalEnd={formFields.endPaymentDate}
                    changeOnly="end"
                    multiCalendar={false}
                    startCalendar={formFields.startPaymentDate}
                    onClick={handleEndPaymentDateClick}
                    onDateChange={(date) =>
                      handleFieldChange('endPaymentDate', date)
                    }
                    ref={endPaymentDateInputRef}
                    disableResume={true}
                  />
                </div>
              </div>
              <div className="flex basis-1/2 flex-col space-y-2.5 lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
                <InputText
                  label="Nome do cliente"
                  error={formErrors.clientName}
                  onChange={(e) =>
                    handleFieldChange('clientName', e.target.value)
                  }
                  value={formFields.clientName}
                  placeholder="Digite o nome do cliente"
                />
              </div>
              <div className="flex basis-1/2 flex-col space-y-2.5  lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
                <InputText
                  label="Nro da reserva"
                  error={formErrors.bookingCode}
                  onChange={(e) =>
                    handleFieldChange('bookingCode', e.target.value)
                  }
                  value={formFields.bookingCode}
                  placeholder="Digite o número da reserva"
                />
              </div>
              <div className="flex flex-col items-center space-y-2.5 lg:flex-row lg:space-x-2.5 lg:space-y-0">
                <span
                  role="button"
                  onClick={handleSubmit}
                  className="group !mt-7 flex h-9 w-9 items-center justify-center rounded-full border border-solid border-secondary bg-white hover:bg-secondary"
                >
                  <BiSearch
                    size={18}
                    className="text-xs text-secondary transition-none group-hover:text-white"
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
      </Container>

      <div className="mt-4 flex justify-end">
        <div className="w-80">
          <Select
            label="Ordenar por"
            value={orderBy}
            options={Object.values(OrderBy).map((value) => ({ value }))}
            onChange={(v) => handleChangeOrderBy(v)}
          />
        </div>
      </div>

      {hasFilter && (
        <div className="mt-5 flex w-full flex-row items-center justify-between border-0 border-b border-t  border-solid border-neutral-400 py-2.5">
          <div>
            {Object.keys(filter())
              .filter((i) => filter()[i]?.length > 0)
              .map((i) => {
                let filterValue = '';
                if (i === 'bookingCode') {
                  filterValue = filter()[i];
                }
                if (i === 'clientName') {
                  filterValue = filter()[i];
                }
                if (i === 'startPaymentDate') {
                  filterValue = format(filter()[i], 'dd/MM/yyyy');
                  if (filter().endPaymentDate) {
                    filterValue = `${filterValue} - ${format(filter().endPaymentDate, 'dd/MM/yyyy')}`;
                  }
                }
                if (filterValue == '') return;
                return (
                  <span
                    onClick={() => {
                      setFormFields((prev) => ({
                        ...prev,
                        [i]: '',
                      }));
                      if (i === 'startPaymentDate') {
                        setFormFields((prev) => ({
                          ...prev,
                          endPaymentDate: '',
                        }));
                      }
                      setSubmit(true);
                    }}
                    key={i}
                    role="button"
                    className="!m mr-4 inline-block rounded-2xl border border-solid border-secondary bg-secondary-100 px-4 py-1.5 text-body font-semibold text-secondary"
                  >
                    {filterValue} <AiOutlineClose className="ml-2.5" />
                  </span>
                );
              })}
          </div>
        </div>
      )}
      <Container className="my-4">
        <h2 className="mb-5 text-heading-2 text-primary">
          Pagamentos recebidos
        </h2>
        <div className="rounded-md border border-solid border-neutral-400 p-2.5">
          <table className="w-full table-auto">
            <thead className="border-b border-solid border-primary text-body font-semibold text-primary">
              <tr>
                <th className="px-1 pb-5 pt-5">Nro. da reserva</th>
                <th className="w-[232px] px-1 pb-5 pt-5">Cliente</th>
                <th className="px-1 pb-5 pt-5">Data do pgto</th>
                <th className="px-1 pb-5 pt-5">Valor pago para o cliente</th>
                <th className="px-1 pb-5 pt-5">Taxa book2b</th>
                <th className="px-1 pb-5 pt-5">Taxa parceiro</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-neutral-400">
              {availableBookings.total > 0 ? (
                availableBookings.result.map((booking) => (
                  <tr key={booking.bookingCode}>
                    <td className="px-1 py-6 text-body font-semibold text-secondary">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleOpenModal(booking.bookingCode)}
                      >
                        {booking.bookingCode}
                      </span>
                    </td>
                    <td className="px-1 py-6 text-body text-neutral-800">
                      {booking.clientName}
                    </td>
                    <td className="px-1 py-6 text-body text-neutral-800">
                      {booking.status === 'PAID'
                        ? format(booking.updatedAt, 'dd/MM/yyyy')
                        : '-'}
                    </td>
                    <td className="px-1 py-6 text-body font-semibold text-price">
                      {formatPrice(booking.AmountToReceive)}
                    </td>
                    <td>
                      {booking.feeAmount
                        ? formatPrice(booking.feeAmount / 2)
                        : '-'}
                    </td>
                    <td>
                      {booking.feeAmount
                        ? formatPrice(booking.feeAmount / 2)
                        : '-'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4">
                    Nenhum resultado foi encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {foundResults && (
          <div className="py-7 text-center">
            <span
              role="button"
              className="text-body font-semibold text-secondary"
              onClick={() => handleLoadMore(page + 1)}
            >
              Ver mais resultados
            </span>
          </div>
        )}
      </Container>

      {openModal && (
        <ModalFinancialBookingData
          reservationId={modalBookingId}
          openModal={openModal}
          onClose={() => {
            setModalBookingId('');
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
}
