import { Role } from '@system/acl';

export const PagePath = {
  Home: '/home',
  RecuperarSenha: '/recuperar-senha',
  NovaSenha: '/nova-senha',
  NovoUsario: '/novo-usuario',
  MeusDados: '/meus-dados',
  Aereo: '/aereo',
  AereoFrota: '/aereo/frota',
  AereoFrotaCadastrar: '/aereo/frota/cadastrar',
  AereoFrotaEditar: '/aereo/frota/:id/editar',
  AereoFrotaAssentos: '/aereo/frota/:id/assentos',
  AereoFrotaRotas: '/aereo/frota/:id/rotas',
  AereoSaidas: '/aereo/saidas',
  AereoSaidasCadastrar: '/aereo/saidas/cadastrar',
  AereoSaidasEditar: '/aereo/saidas/:id/editar',
  AereoSaidasPrecificar: '/aereo/saidas/:id/precificar',
  AereoReservas: '/aereo/reservas',
  AereoReservasResultados: '/aereo/reservas/resultados',
  AereoReservasCheckout: '/aereo/reservas/checkout',
  AereoReservasPagamento: '/aereo/reservas/pagamento',
  AereoReservasConfirmar: '/aereo/reservas/confirmar',
  Hotel: '/hotel',
  Rodoviario: '/rodoviario',
  PacoteFixo: '/pacote-fixo',
  Admin: '/admin',
  AdminRegrasTarifarias: '/admin/regras-tarifarias',
  AdminEmpresas: '/admin/empresas',
  AdminEmpresasCadastrar: '/admin/empresas/cadastrar',
  // AdminAgencias: '/admin/agencias',
  AdminCompanhias: '/admin/companhias',
  AdminAeroportos: '/admin/aeroportos',
  AdminAssentos: '/admin/assentos',
  AdminClasses: '/admin/classes',
  AdminFabricantes: '/admin/fabricantes',
  AdminServicosBordo: '/admin/servicos-bordo',
  AdminUsuarios: '/admin/usuarios',
  AdminUsuarioEdit: '/admin/usuarios/:id/editar',
  AdminUsuariosNovo: '/admin/usuarios/novo',
  Relatorios: '/relatorios',
  Relatorio: '/relatorio',
  Financeiro: '/financeiro',
  FinanceiroRecebiveis: '/financeiro/recebiveis',
  FinanceiroMultasReembolsos: '/financeiro/multas-reembolsos',
  FinanceiroMultasReembolsosDetalhes: '/financeiro/multas-reembolsos/:id',
  FinanceiroRecebiveisAdmin: '/financeiro/recebiveis-adm',
  FinanceiroRecebiveisReservasEscolhidas:
    '/financeiro/recebiveis/reservas-escolhidas',
  Reservas: '/reservas',
  ReservasGerenciar: '/reservas/gerenciar',
  DetalhesReserva: '/reservas/detalhes/:id',
  ConferenciaReservasExternas: '/pesquisar-reservas-externas',
  CheckoutV2: '/checkoutV2',
  CheckoutV2Confirmacao: '/checkoutV2/confirmacao',
  NovaReserva: '/nova-reserva',
  GoogleCallback: '/auth/google/callback',
  GoogleActiveAccountCallback: '/activate/google/callback',
  ActivateAccount: '/activate-account/:token',
};

const adminOnly = [
  Role.Gestao,
  Role.Administrador,
  Role.CompradorAdministrador,
];

const allRolesPermited = [
  Role.Operacional,
  Role.Financeiro,
  Role.ProdutosInternos,
  Role.Comprador,
  Role.Gestao,
  Role.Administrador,
  Role.CompradorAdministrador,
];

export const pagesACL = {
  [PagePath.Aereo]: adminOnly,
  [PagePath.AereoFrota]: adminOnly,
  [PagePath.AereoFrotaCadastrar]: adminOnly,
  [PagePath.AereoFrotaEditar]: adminOnly,
  [PagePath.AereoFrotaAssentos]: adminOnly,
  [PagePath.AereoFrotaRotas]: adminOnly,
  [PagePath.AereoSaidas]: adminOnly,
  [PagePath.AereoSaidasCadastrar]: adminOnly,
  [PagePath.AereoSaidasEditar]: adminOnly,
  [PagePath.AereoSaidasPrecificar]: adminOnly,
  [PagePath.AereoReservas]: allRolesPermited,
  [PagePath.AereoReservasResultados]: allRolesPermited,
  [PagePath.AereoReservasCheckout]: allRolesPermited,
  [PagePath.AereoReservasPagamento]: allRolesPermited,
  [PagePath.Hotel]: allRolesPermited,
  [PagePath.Rodoviario]: allRolesPermited,
  [PagePath.PacoteFixo]: allRolesPermited,
  [PagePath.Admin]: adminOnly,
  [PagePath.AdminRegrasTarifarias]: adminOnly,
  [PagePath.AdminEmpresas]: adminOnly,
  [PagePath.AdminEmpresasCadastrar]: adminOnly,
  [PagePath.AdminCompanhias]: adminOnly,
  [PagePath.AdminAeroportos]: adminOnly,
  [PagePath.AdminAssentos]: adminOnly,
  [PagePath.AdminClasses]: adminOnly,
  [PagePath.AdminFabricantes]: adminOnly,
  [PagePath.AdminServicosBordo]: adminOnly,
  [PagePath.AdminUsuarios]: adminOnly,
  [PagePath.AdminUsuariosNovo]: adminOnly,
  [PagePath.AdminUsuarioEdit]: adminOnly,
  [PagePath.Relatorios]: allRolesPermited,
  [PagePath.Relatorio]: allRolesPermited,
  [PagePath.Financeiro]: allRolesPermited,
  [PagePath.FinanceiroRecebiveis]: allRolesPermited,
  [PagePath.FinanceiroMultasReembolsos]: allRolesPermited,
  [PagePath.ConferenciaReservasExternas]: allRolesPermited,
  [PagePath.FinanceiroRecebiveisAdmin]: allRolesPermited,
  [PagePath.FinanceiroRecebiveisReservasEscolhidas]: allRolesPermited,
  [PagePath.Reservas]: allRolesPermited,
  [PagePath.DetalhesReserva]: allRolesPermited,
  [PagePath.FinanceiroMultasReembolsosDetalhes]: allRolesPermited,
  [PagePath.ReservasGerenciar]: allRolesPermited,

  [PagePath.NovaReserva]: allRolesPermited,
};
