import { useState, useEffect, useCallback } from 'react';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import { FiEdit } from 'react-icons/fi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { BiSolidEdit } from 'react-icons/bi';
import { PagePath } from '../pages-config';
import api from '@services/api';
import PageHeader from '@components/page-header';
import LoadingAnimation from '../components/LoadingAnimation';

const ALERT_TIMEOUT = 3000;
const LOADING_TIMEOUT = 500;
const PAGE_TITLE = 'Serviço de Bordo';

export default function AdminOnboardServices() {
  const [alertState, setAlertState] = useState({
    type: '',
    title: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [onboardServices, setOnboardServices] = useState([]);
  const [totalServices, setTotalServices] = useState(0);

  const showAlert = useCallback((type, title, message) => {
    setAlertState({ type, title, message });
    setTimeout(
      () => setAlertState({ type: '', title: '', message: '' }),
      ALERT_TIMEOUT,
    );
  }, []);

  const fetchOnboardServices = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/onboard-services');
      if (response.data.length > 0) {
        setOnboardServices(response.data);
        setTotalServices(response.data.length);
      }
    } catch (error) {
      showAlert('alert-danger', 'Erro!', 'Erro ao carregar os serviços.');
    }
    setIsLoading(false);
  }, [showAlert]);

  useEffect(() => {
    fetchOnboardServices();
  }, [fetchOnboardServices]);

  const resetForm = useCallback(() => {
    setIsLoading(true);
    setIsNewRecord(true);
    setSelectedId('');
    setServiceName('');
    setTimeout(() => setIsLoading(false), LOADING_TIMEOUT);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (isNewRecord) {
        await api.post('/onboard-services', {
          serviceName: serviceName.trim(),
        });
        showAlert('alert-success', 'Sucesso!', 'Cadastrado com sucesso.');
      } else {
        await api.patch(`/onboard-services/${selectedId}`, {
          serviceName: serviceName.trim(),
        });
        showAlert('alert-success', 'Sucesso!', 'Alterado com sucesso.');
      }

      resetForm();
      await fetchOnboardServices();
    } catch (error) {
      showAlert('alert-danger', 'Erro!', 'Erro ao processar a requisição.');
    }

    setIsLoading(false);
  };

  const handleEdit = useCallback(
    (id, name) => {
      setIsLoading(true);
      resetForm();
      setIsNewRecord(false);
      setSelectedId(id);
      setServiceName(name);
      setTimeout(() => setIsLoading(false), LOADING_TIMEOUT);
    },
    [resetForm],
  );

  const handleSearch = async () => {
    setIsLoading(true);
    setAlertState({ type: '', title: '', message: '' });

    try {
      const endpoint = searchTerm
        ? `/onboard-services/search?term=${searchTerm}`
        : '/onboard-services';

      const response = await api.get(endpoint);

      if (response.data.length > 0) {
        setOnboardServices(response.data);
      } else {
        showAlert('alert-danger', 'Dados não encontrados!', '');
      }
    } catch (error) {
      showAlert('alert-danger', 'Erro!', 'Erro ao buscar serviços.');
    }

    setIsLoading(false);
  };

  const handleSearchChange = async (value) => {
    setSearchTerm(value);
    setAlertState({ type: '', title: '', message: '' });

    if (!value.trim()) {
      await fetchOnboardServices();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      handleSearch();
    }
  };

  return (
    <>
      {isLoading && <LoadingAnimation />}
      <div
        className={alertState.type ? 'alert ' + alertState.type : 'hide'}
        role="alert"
      >
        <h4 className={alertState.title ? '' : 'hide'}>{alertState.title}</h4>
        {alertState.message}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Serviços de Bordo',
            link: PagePath.AdminServicosBordo,
          },
        ]}
        title={PAGE_TITLE}
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-9">
                  <label>Característica</label>
                  <input
                    type="text"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-3">
                  <button type="submit">
                    <span className={isLoading ? 'hide' : ''}>
                      <span className={isNewRecord ? '' : 'hide'}>
                        <PiCaretRightBold /> Salvar
                      </span>
                      <span className={!isNewRecord ? '' : 'hide'}>
                        <FiEdit /> Alterar
                      </span>
                    </span>
                    <span className={isLoading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{PAGE_TITLE}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={handleSearch} />
            </div>
            <div className="rightBtn">
              <div>{onboardServices.length}</div>
              <div>de</div>
              <div className="listTotal">{totalServices}</div>
              <button type="button" className="btn btnNew" onClick={resetForm}>
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm">
          <table className="tblDefault">
            <thead>
              <tr>
                <th scope="col" className="tdMaster">
                  Nome
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {onboardServices.map((service) => (
                <tr
                  key={service.id}
                  onClick={() => handleEdit(service.id, service.serviceName)}
                  className={selectedId === service.id ? 'trSelect' : ''}
                >
                  <td>{service.serviceName}</td>
                  <td>
                    <BiSolidEdit />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
