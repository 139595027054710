import { useEffect, useState } from 'react';
import { HeaderV2 } from '../HeaderV2';
import { Menu } from '../Menu';

export function DashboardV2({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        typeof window !== 'undefined' &&
        typeof window.FreshworksWidget === 'function'
      ) {
        window.FreshworksWidget('hide', 'launcher');
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  function handleMenuClick() {
    setIsMenuOpen((prev) => !prev);
  }

  return (
    <div className="flex h-screen w-full flex-col lg:relative">
      <div className="w-full shadow-header lg:fixed lg:z-30">
        <HeaderV2 isMenuOpen={isMenuOpen} handleMenuClick={handleMenuClick} />
        <Menu isMenuOpen={isMenuOpen} handleMenuClick={handleMenuClick} />
      </div>
      <div className="flex h-screen lg:pt-[120px]">
        <div className="no-scrollbar w-full overflow-auto p-5">{children}</div>
      </div>
    </div>
  );
}
