// SELECT-INPUTS

import { Role } from '@system/acl';

export const SELECT_OPTIONS_ADMIN = [
  { value: Role.Operacional, label: 'Operacional' },
  { value: Role.Financeiro, label: 'Financeiro' },
  { value: Role.ProdutosInternos, label: 'Produtos' },
  { value: Role.Comprador, label: 'Comprador' },
  { value: Role.Gestao, label: 'Gestão' },
  { value: Role.Administrador, label: 'Administrador' },
  { value: Role.CompradorAdministrador, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_BUYER_ADMIN = [
  { value: Role.Comprador, label: 'Comprador' },
  { value: Role.CompradorAdministrador, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_STATUS = [
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' },
];

export const SELECT_OPTIONS_CNPJ_STATUS = [
  { value: 'Ativo', label: 'Ativo' },
  { value: 'Baixada', label: 'Baixada' },
];

export const TYPE_COMPANY = [
  { label: 'Hotel', value: 'Hotel' },
  { label: 'Agência', value: 'Agência' },
];

export const PAYMENT_METHODS = [
  { label: 'Faturamento', value: 'invoicing', active: true },
  {
    label: 'Cartão de crédito cliente',
    value: 'client_credit_card',
    active: true,
  },
  { label: 'VPCard', value: 'vpcard', active: false },
  { label: 'Pix', value: 'pix', active: false },
  { label: 'Pix agendado', value: 'scheduled_pix', active: false },
];

export const SEGMENTS = [
  { value: '29', label: '0029 - Aeroportos' },
  {
    value: '194',
    label: '0194 - Agências de Análise de Crédito de Consumidores',
  },
  { value: '193', label: '0193 - Anúncios / Classificados / Propaganda' },
  { value: '169', label: '0169 - Casas de Câmbio' },
  { value: '76', label: '0076 - Comércio em Geral' },
  { value: '28', label: '0028 - Companhias Aéreas e Taxi Aereo' },
  {
    value: '32',
    label:
      '0032 - Cooperativas de Taxi e Serviços de Transporte não Classificados',
  },
  { value: '170', label: '0170 - Corretor de Seguros' },
  { value: '25', label: '0025 - Cruzeiros e Cia Marítima' },
  { value: '212', label: '0212 - Estacionamento de Automóveis' },
  { value: '235', label: '0235 - Exposições e Atrações Turísticas' },
  { value: '173', label: '0173 - Hotéis' },
  {
    value: '21',
    label: '0021 - Linhas de Ônibus, Transporte de Passageiros e Escolar',
  },
  { value: '209', label: '0209 - Locadoras de Veículos' },
  { value: '26', label: '0026 - Nautica - Aluguel e Arrendamento de Navios' },
  { value: '30', label: '0030 - Operadores / Agências de Viagem' },
  { value: '240', label: '0240 - Parque de Diversões / Espetáculos Circenses' },
  { value: '201', label: '0201 - Processamento de Dados e Serviços' },
  { value: '20', label: '0020 - Táxi / Limousines' },
  {
    value: '230',
    label: '0230 - Teatros / Shows / Concertos / Entretenimento em Geral',
  },
  { value: '17', label: '0017 - Transportes Urbanos - Terrestres e Balsas' },
  { value: '18', label: '0018 - Trem de Passageiros e Cia Ferroviária' },
];

export const LINE_OF_BUSINESS_OPTIONS = [
  { value: '29', label: 'Agência de Turismo' },
  { value: '26', label: 'Diversos' },
  { value: '28', label: 'Outros' },
  { value: '27', label: 'Seguros' },
];
